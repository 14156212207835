@import "../../theme";

.error-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .container {
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        div {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .text {
                font-size: 20px;
                margin-bottom: 3rem;
            }

            button {
                justify-content: center;
                margin-bottom: 2rem;
                width: auto;
                cursor: pointer;
            }

            .support-button {
                color: $color-warm-sand;
                cursor: pointer;
            }
        }
    }
}

.stats-error {
    max-width: calc(25% - 1.33333rem);
    min-width: 296px;
    width: 100%;
    height: 380px;
    flex-grow: 1;
    background: $color-snow;
    margin-right: 1.33rem;
    margin-bottom: 1.33rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
        font-weight: bold;
    }

    button {
        margin-top: 2rem;
    }
}

@import '../../../../../theme';

.listItem {
  display: block;
  flex-shrink: 0; // IE 11
}

.wrapper {
  flex: 1;
  display: block;
  align-items: flex-start;
  flex-direction: column;
  margin-top: size(8);
  margin-bottom: size(8);
  justify-content: center;
}

.title {
  font-size: 2rem;
  font-family: $font-bold;
  color: $color-cloudy-sky;
}

.content {
  margin-top: 1.66rem;
  line-height: 2.33rem;
  font-size: 2.166rem;
  font-weight: 500;
  color: $color-dark-sky;

  &.sentimentMarkerWrapper {
    margin-top: 0.5rem;
  }
}
@import '../../theme';

.wrapper {
  .icon {
    width: 1em;
    height: 1em;

    @each $color, $value in $infomedia-colors {
      &.#{$color} {
        fill: $value;
      }
    }
  }

  &.verticalCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

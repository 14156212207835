.Toastify__toast-container {
    --toastify-toast-width: auto;
    --toastify-toast-min-height: 8rem;
    --toastify-font-family: 'Roboto Medium', Arial, Helvetica, sans-serif;
    --toastify-color-success: #33B577;
    --toastify-color-error: #D64949;
}

.Toastify__toast {
    border-radius: 3px;
    font-size: 2.333rem;
    padding: 15px 12px 15px 24px;
}

.Toastify__toast [class*='icon'] {
    font-size: 4rem;
}

.Toastify__toast-body {
    padding: 0;
}

.Toastify__toast-icon {
    margin-inline-end: 8px;
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-error: var(--toastify-color-error);
}

.Toastify__toast-icon svg {
    font-size: 3rem;
}

.Toastify__toast--success {
    color: var(--toastify-color-success);
    background-color: #E1F9ED;
    border: 1px solid rgba(51, 181, 119, 0.6);
}

.Toastify__toast--success [class*='icon'] {
    fill: var(--toastify-color-success);
}

.Toastify__toast--error {
    color: var(--toastify-color-error);
    background-color: #FFC2C5;
    border: 1px solid rgba(214, 73, 73, 0.6);
}

.Toastify__toast--error [class*='icon'] {
    fill: var(--toastify-color-error);
}
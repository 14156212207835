@function size($size) {
    @return $size * $base_size_unit;
}

@function op-pallet($colorName) {
    $colorName: inspect($colorName);
  
    @if $colorName == "pink" or $colorName == '"pink"' {
      @return $color-light-berry;
  
    } @else if $colorName == "red" or $colorName == '"red"' {
      @return $color-berry;
  
    } @else if $colorName == "turquoise" or $colorName == '"turquoise"' {
      @return $color-light-sea;
  
    } @else if $colorName == "darkblue" or $colorName == '"darkblue"' {
      @return $color-sea;
  
    } @else if $colorName == "lightgreen" or $colorName == '"lightgreen"' {
      @return $color-moss;
  
    } @else if $colorName == "green" or $colorName == '"green"' {
      @return $color-forest;
  
    } @else if $colorName == "yellow" or $colorName == '"yellow"' {
      @return $color-cold-sand;
  
    } @else if $colorName == "orange" or $colorName == '"orange"' {
      @return $color-warm-sand;
  
    } @else if $colorName == "blue" or $colorName == '"blue"' {
      @return $color-blue-sky;
  
    } @else {
      @return $color-cloudy-sky;
    }
  }

  @function highlight-pallet($colorName) {
    $colorName: inspect($colorName);
  
    @if $colorName == "red" or $colorName == '"red"' {
      @return #FFC2C5;
  
    } @else if $colorName == "orange" or $colorName == '"orange"' {
      @return #FDD590;
  
    } @else if $colorName == "yellow" or $colorName == '"yellow"' {
      @return #FDD590;
  
    } @else if $colorName == "green" or $colorName == '"green"' {
      @return #89EABC;
  
    } @else if $colorName == "turquoise" or $colorName == '"turquoise"' {
      @return #DCDADD;
  
    } @else if $colorName == "blue" or $colorName == '"blue"' {
      @return #A0DEF8;
  
    } @else if $colorName == "darkblue" or $colorName == '"darkblue"' {
      @return #B7D2F7;
  
    } @else if $colorName == "lightgreen" or $colorName == '"lightgreen"' {
      @return #E1F9ED;
  
    } @else if $colorName == "pink" or $colorName == '"pink"' {
      @return #FFC2C5;
  
    } @else {
      @return #DCDADD;
    }
  }

/*use for stripping button style*/
@mixin unstyled_button($size) {
  width: $size;
  height: $size;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: $size;
  line-height: $size;
  background-color: transparent;
}

@mixin no-selection {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
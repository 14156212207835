@import '../../new-components/theme';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100vw;

  img {
    width: 300px;
    height: auto;
  }

  h3 {
    margin: 3rem;
  }
}

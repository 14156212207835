@import '../../../theme';

.sentimentMarker {
  display: flex;

  align-items: center;

  justify-content: center;

  border: 1px solid $color-warm-grey;
  border-radius: 50%;

  .sentimentMarkerFilling {
    border: 0;
    border-radius: 50%;
        
    &.positive {
      background: $color-forest;
    }

    &.negative {
      background: $color-light-berry;
    }

    &.neutral {
      background: $color-grey;
    }

    &.balanced {
      background: linear-gradient($color-light-berry 50%, $color-forest 50%); 
    }
  }

  &.big {
    width: 4rem;
    height: 4rem;

    .sentimentMarkerFilling {
      width: 3rem;
      height: 3rem;
    }
  }

  &.small {
    width: 2rem;
    height: 2rem;

    .sentimentMarkerFilling {
      width: 1.33rem;
      height: 1.33rem;
    }
  }
}

@import '../../theme';

.loader {
  margin: 2rem auto !important;
  font-size: 1rem !important;
  position: relative;
  text-indent: -9999em;
  border-top: 1rem solid rgba($color-cold-sand, .5) !important;
  border-right: 1rem solid rgba($color-cold-sand, .5) !important;
  border-bottom: 1rem solid rgba($color-cold-sand, .5) !important;
  border-left: 1rem solid $color-warm-sand !important;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50% !important;
  width: 9rem !important;
  height: 9rem !important;

  &:after{
    border-radius: 50% !important;
    width: 9rem !important;
    height: 9rem !important;
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@import "../../theme";

button.button {
    font-size: 2.333rem;
    line-height: 2.733rem;
    height: 5rem;
    padding: 1rem 3rem;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
        opacity: 0.5;
    }

    &.iconOnly {
        padding: 0 0.5rem;

        svg {
            font-size: 4rem;
            line-height: 0;
        }
    }

    &.iconAndChildren {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &:focus-visible{
        box-shadow: none;
    }

    &.default {
        color: $color-dark-sky;
        fill: $color-dark-sky;
        background-color: $color-snow;
        border: 1px solid $color-warm-grey;

        &:hover,
        &:focus {
            color: $color-warm-sand;
            fill: $color-warm-sand;
            border: 1px solid $color-warm-sand;
        }
    }

    &.solid {
        color: $color-snow;
        fill: $color-snow;
        background-color: $color-warm-sand;
        border: 1px solid $color-warm-sand;

        svg {
            fill: $color-snow;
        }

        &:hover,
        &:focus {
            background-color: $color-cold-sand;
            border: 1px solid $color-cold-sand;
        }

        &:focus-visible {
            box-shadow: none;
        }
    }

    &.text {
        background: none;
        border: 0;
        color: $color-warm-sand;
        fill: $color-warm-sand;

        & > span {
            vertical-align: middle;
        }

        &:focus > span {
            color: $color-warm-sand !important;
        }
    }

    &.link {
        background: none;
        border: 0;
        color: $color-dark-sky;
        fill: $color-dark-sky;

        & > span {
            vertical-align: middle;
        }

        &:hover {
            background-color: transparent;
            color: $color-warm-sand;
            svg {
                fill: $color-warm-sand;
            }
        }

        &:focus {
            color: $color-cold-sand !important;
            svg {
                fill: $color-cold-sand;
            }
        }
    }

    & > span {
        margin-right: 1rem;

        &:only-child {
            margin-right: 0;
            display: flex;
        }

        &:not(:only-child):first-child {
            display: inline-block;
            height: 3rem;
        }

        & > svg {
            font-size: 3rem;
        }

        :hover {
            background-color: transparent;
            color: $color-warm-sand;
        }
    }
}

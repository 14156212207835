@import './theme';

/* ---- RESET ---- */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* ---- STYLES ---- */

@font-face {
    font-family: 'Roboto';
    src: url(fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: 'Roboto Medium';
    src: url(fonts/Roboto-Medium.ttf);
}
@font-face {
    font-family: 'Roboto Italic';
    src: url(fonts/Roboto-Italic.ttf);
}
@font-face {
    font-family: 'Roboto Bold';
    src: url(fonts/Roboto-Bold.ttf);
}
@font-face {
    font-family: 'Roboto Bold Italic';
    src: url(fonts/Roboto-BoldItalic.ttf);
}
@font-face {
    font-family: 'Roboto Black';
    src: url(fonts/Roboto-Black.ttf);
}
@font-face {
    font-family: 'Roboto Black Italic';
    src: url(fonts/Roboto-BlackItalic.ttf);
}

html {
    font-size: 6px; /* Base unit */
    background: $color-snow;
    overflow: hidden;
}

html.mobile{
    overflow: auto;
    background-color: $color-light-rock;
}

body {
    margin: 0;
    width: 100%;
    font-size: 2.5rem;
    line-height: 1.5em;
    font-family: $font;
    font-weight: normal;
    color: $color-dark-sky;
}

#root {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

#root.mobile {
    height: auto;
}

#main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
}

ul {
    list-style: none;
}

/* --- Headings --- */
h1 {
    font-size: 5rem;
    line-height: 1.4em;
    font-family: $font-bold;
    font-weight: normal;
    margin: 0 0 2rem 0;
}
h2 {
    font-size: 3.3333rem;
    line-height: 1.4em;
    font-family: $font-bold;
    font-weight: normal;
    margin: 0 0 4rem 0;
}
h3 {
    font-size: 3rem;
    line-height: 1.4em;
    margin: 0 0 1.5rem 0;
}

/* --- Text --- */
p {
    font-size: 2.5rem;
    line-height: 1.4em;
    font-family: $font;
    margin-bottom: 3rem;
}

a {
    color: $color-warm-sand;

    &:hover {
        color: $color-warm-sand;
        text-decoration: none;
    }
    &:focus {
        color: $color-cold-sand;
        outline: none;
    }

    &.solid{
        font-size: 2.333rem;
        line-height: 2.733rem;
        height: 5rem;
        padding: 1rem 3rem;
        border-radius: 3px;
        color: $color-snow;
        background-color: $color-warm-sand;
        border: 1px solid $color-warm-sand;

        &:hover {
            background-color: $color-cold-sand;
            border: 1px solid $color-cold-sand;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        color: inherit;

        &:hover {
            color: $color-warm-sand;
        }
    }
}

/* --- Font weights --- */
strong {
    font-weight: normal;
    font-family: $font-bold;
}
em {
    font-style: normal;
    font-family: $font-italic;
}
    strong > em,
    em > strong {
        font-style: normal;
        font-weight: normal;
        font-family: $font-bold-italic;
    }

/* Add every element that uses --font-bold to the selectors */
h1 strong,
h2 strong {
    font-family: $font-black;
}
h1 em,
h2 em {
    font-family: $font-bold-italic;
}
    h1 strong > em,
    h2 em > strong {
        font-family: $font-black-italic;
    }

/* --- Text input --- */
label {
    display: flex;
    flex-direction: column;
    font-size: 2.3rem;
    line-height: 1.2em;
}
    label > input[type="text"],
    textarea {
        margin: 1rem 0;
        padding: 2rem;
        border: 1px solid $color-cloudy-sky;
        border-radius: 3px;
        color: $color-dark-sky;
        font-family: $font;
        font-size: 2.3rem;
    }
        label > input::placeholder {
            color: $color-warm-grey;
        }

/* --- Keyboard focus --- */
#keyboard-skip {
    position: absolute;
    top: 1rem;
    left: 0;
    padding: 2rem;
    border: 1px solid $color-cloudy-sky;
    background-color: $color-subtle-grey;
    opacity: 0;

    &:focus-within {
        z-index: 100;
        opacity: 100;
    }
}

button:focus-visible,
a:focus-visible,
li:focus-visible {
    box-shadow: $focus-box-shadow;

    &.inset-focus-marker:focus-visible {
        box-shadow: $focus-box-shadow-inset;
    }
}

/* --- Article --- */
article {
    width: 100%;
    box-sizing: border-box;
}

    article header {
        font-size: 2rem;
        line-height: 1.4em;
        margin: 0 0 3rem 0;
    }

    article figcaption {
        font-size: 2.333rem;
        line-height: 1.2em;
        color: $color-cloudy-sky;
        font-family: $font;
        margin: 2rem 0 4rem 0;
    }

    /* Matches returns P for now */
    article figcaption p {
        font-size: inherit;
    }

    article img {
        width: 100%;
        margin: 0 0 1.3rem 0;
    }
        article img.portrait {
            max-width: 300px;
        }

        article p {
            font-size: 3rem;
        }

/* --- Tooltip --- */
.tooltip-text {
    position: relative;
    display: inline-block;
}
.tooltip {
    position: absolute;
    z-index: 10000;
    display: block;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 2rem;
    opacity: 0;
     }
    .tooltip.in {
      opacity: 1; }
    .tooltip.top {
      margin-top: -3px;
      padding: 5px 0; }
    .tooltip.right {
      margin-left: 3px;
      padding: 0 5px; }
    .tooltip.bottom {
      margin-top: 3px;
      padding: 5px 0; }
    .tooltip.left {
      margin-left: -3px;
      padding: 0 5px; }

  .tooltip-inner {
    /* max-width: 200px; */
    padding: 2rem;
    color: #fff;
    text-align: center;
    background-color: $color-dark-sky;
    border-radius: 4px; }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }

  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-dark-sky; }

  .tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-dark-sky; }

  .tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-dark-sky; }

  .tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: $color-dark-sky; }

  .tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: $color-dark-sky; }

  .tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-dark-sky; }

  .tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-dark-sky; }

  .tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-dark-sky; }

/* --- Markers --- */
.bottom-marker {
    width: 100%;
    height: 3px;
    background: $color-warm-sand;
}
    .bottom-marker.tall {
        height: 6px
    }

/* --- Article list --- */
    section.article-list li {
        list-style: none;
        padding: 4rem;
        display: flex;
        flex-direction: row;
    }
        section.article-list li figure {
            width: 78px;
            height: 78px;
            border-radius: 6px;
            background: $color-warm-grey;
        }
            section.article-list li figure img {
                width: 78px;
                height: 78px;
            }
        section.article-list li header {
            font-size: 2rem;
            line-height: 1.4em;
            margin: 0 0 1rem 0;
        }
        section.article-list li footer {
            font-size: 1.8rem;
            line-height: 1.4em;
        }

/* --- Scrollbars --- */
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $color-warm-grey $color-subtle-grey;
  }


body.styled-scrollbars .op-content-left_wrapper{
    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        height: 12px;
        width: 2rem;
    }

    *::-webkit-scrollbar-track,
    *::-webkit-scrollbar-thumb {
        background: transparent;
        border-color: transparent;
    }

    *:hover::-webkit-scrollbar-track {
        background: $color-light-rock;
    }

    *:hover::-webkit-scrollbar-thumb {
        background-color: $color-warm-grey;
        border-radius: 6px;
        border: 2px solid $color-light-rock;
    }
}

/* --- Hotfixes --- */
#lightboxBackdrop {
    [class^="header"] {
        position: relative;
        z-index: 1;
    }
}

/* Font families */
$font: 'Roboto', Arial, Helvetica, sans-serif;
$font-semi-bold: 'Roboto Medium', Arial, Helvetica, sans-serif;
$font-bold: 'Roboto Bold';
$font-italic: 'Roboto Italic';
$font-bold-italic: 'Roboto Bold Italic';
$font-black: 'Roboto Black';
$font-black-italic: 'Roboto Black Italic';

$base_size_unit: 0.25rem;

$types-pallet: 4 "blue", 5 "green", 6 "red", 7 "lightgreen", 8 "orange", 9 "turquoise", 10 "yellow", 11 "darkblue", 12 "pink";

/* Old colors (TODO: delete when new colors implemented) */
$opoint-blue: #78acd7;
$opoint-shade-blue: #3e7eb3;
$opoint-gray: #6f747d;
$opoint-green: #67c897;
$opoint-dark-green: darken($opoint-green, 10%);
$opoint-header-icons-gray: #9F9F9F;
$opoint-hover-gray: #b7b8bc;
$opoint-hover-dark-gray: darken(#878b9c, 15%);
$opoint-bg-gray: #fafafa;
$opoint-shadow: rgba(0, 0, 0, 0.2);
$opoint-warning-red: #f26d7d;

/* New color scheme - Figma */
$color-dark-sky: #001F4A;
$color-cloudy-sky: #7F8FA4;
$color-blue-sky: #648CC3;
$color-light-sea: #7BB8D1;
$color-sea: #336174;
$color-warm-sand: #D39326;
$color-cold-sand: #E2B161;
$color-forest: #7A9652;
$color-moss: #B0C18E;
$color-berry: #D64949;
$color-light-berry: #FF8186;
$color-lighter-berry: #FFC2C5;
$color-warm-grey: #C4C2C6;
$color-grey: #E5E5E5;
$color-light-rock: #F2F1F2;
$color-subtle-grey: #F8F8F8;
$color-snow: #FFFFFF;
$color-spring: #33B577;
$color-algue: #E1F9ED;

$icomoon-font-family: "opoint" !default;

/* Focus styles */
$focus-box-shadow: 0 0 0 1px $color-cloudy-sky, 0 0 3px 1px $color-cloudy-sky;
$focus-box-shadow-inset: 0 0 0 1px $color-cloudy-sky inset, 0 0 3px 1px $color-cloudy-sky inset;

/* All colors */
$infomedia-colors: (
  'dark-sky': $color-dark-sky,
  'cloudy-sky': $color-cloudy-sky,
  'blue-sky': $color-blue-sky,
  'light-sea': $color-light-sea,
  'sea': $color-sea,
  'warm-sand': $color-warm-sand,
  'cold-sand': $color-cold-sand,
  'forest': $color-forest,
  'moss': $color-moss,
  'spring': $color-spring,
  'algue': $color-algue,
  'berry': $color-berry,
  'light-berry': $color-light-berry,
  'lighter-berry': $color-lighter-berry,
  'warm-grey': $color-warm-grey,
  'grey': $color-grey,
  'light-rock': $color-light-rock,
  'subtle-grey': $color-subtle-grey,
  'snow': $color-snow,
);

/* Transition */
$transition-duration: 300ms;
